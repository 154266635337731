<template>
  <div class="b-list-group">
    <b-list-group flush>
      <b-list-group-item tag="button" :class="['list-group-item-action', actual==='pipeline'?'active':'']" @click="changeActual('pipeline','/apps/crm-pipelines')"><i class="bx bx-filter me-2"></i>Pipelines</b-list-group-item>
      <b-list-group-item tag="button" :class="['list-group-item-action', actual==='users'?'active':'']" @click="changeActual('users','/users')"><i class="ri-user-add-line me-2"></i>Usuários</b-list-group-item>
      <b-list-group-item tag="button" :class="['list-group-item-action', actual==='custom_fields'?'active':'']" @click="changeActual('custom_fields','/invoices/list')"><i class="mdi mdi-layers-triple me-2"></i>Campos Customizados</b-list-group-item>
      <b-list-group-item tag="button" :class="['list-group-item-action', actual==='integrations'?'active':'']" @click="changeActual('integrations','/hooks')"><i class="mdi mdi-power-plug-outline me-2"></i>Integrações</b-list-group-item>
      <b-list-group-item tag="button" :class="['list-group-item-action', actual==='tokens'?'active':'']" @click="changeActual('tokens','/integration-token')"><i class="mdi mdi-fingerprint me-2"></i>Token Integração</b-list-group-item>
      <b-list-group-item tag="button" :class="['list-group-item-action', actual==='automations'?'active':'']" @click="changeActual('automations','/automations')"><i class="mdi mdi-tools me-2"></i>Automações</b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
export default {
  name: "SettingsSide",
  props: {
    actualRoute: {
      type: String,
      default: "pipeline",
    },
  },
  data() {
    return {
      actual: "pipeline",
    };
  },
  methods: {
    changeActual(actual,url) {
      this.actual = actual;
      this.$router.push({"path":url});
    },
  },
  watch: {
    actualRoute (val) {
      if (val !== '') {
        this.actual = val;
      }
    },
  },
  mounted() {
    this.actual = this.actualRoute;
  }
};
</script>
