<script>
  import "flatpickr/dist/flatpickr.css";
  import { overlay } from "@/state/helpers";
  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import SettingsSide from '@/components/settings-side.vue';
  import appConfig from "../../../../app.config";
  import {callUrl, deleteEntity} from '@/helpers/api-apolo';
  import Lottie from "@/components/widgets/lottie.vue";
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  import 'vue-tel-input/dist/vue-tel-input.css';
  import {DateTime} from "luxon";
  import {getTimezone, saError} from "@/helpers/global-functions";
  import Swal from "sweetalert2";



  export default {
    page: {
      title: "Pipelines",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    components: {
      SettingsSide,
      Layout,
      PageHeader,
      lottie: Lottie
    },
    data() {
      return {
        title: "Pipelines",
        items: [{
            text: "Configurações",
            href: "/",
          },
          {
            text: "Pipelines",
            active: true,
          },
        ],
        timeConfig: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        date2: null,
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        page: 1,
        perPage: 8,
        pages: [],
        value: null,
        searchQuery: null,
        date: null,
        pipelinesData: [],
        query: '',
        selectAll: false,
        filter: {
          search: '',
          status: '',
          user_id: '',
          phase: '',
          date: null,
        }
      };
    },
    computed: {
    },
    methods: {
      ...overlay,
      async getAllPipelines() {
        try {
          this.pipelinesData = [];
          let url = '/pipeline';
          if (this.query !== '') {
            url += '?query=' + encodeURI(this.query)
          }
          let data = await callUrl({}, url, 'get');
          for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            obj.value = data[i].id;
            obj.label = data[i].name;
            obj.phases_formatted = data[i].phases.map((phase) => {
              return phase.name;
            }).join(', ');
            obj.status_formatted = data[i].is_active ? '<i class="bx bx-power-off" style="    transition: all .4s;font-size: 24px; color: green"></i>' : '<i class="bx bx-power-off" style="    transition: all .4s;font-size: 24px; color: red"></i>';
            obj.is_default_formatted = data[i].is_default ? '<i class="ri-thumb-up-fill" style="    transition: all .4s;font-size: 24px; color: green"></i>' : '<i class="ri-thumb-down-fill" style="    transition: all .4s;font-size: 24px; color: red"></i>';
            obj.automatic_distribution_formatted = data[i].automatic_distribution ? '<i class="ri-thumb-up-fill" style="    transition: all .4s;font-size: 24px; color: green"></i>' : '<i class="ri-thumb-down-fill" style="    transition: all .4s;font-size: 24px; color: red"></i>';
            obj.attendants_only_see_your_own_businesses_formatted = data[i].attendants_only_see_your_own_businesses ? '<i class="ri-thumb-up-fill" style="    transition: all .4s;font-size: 24px; color: green"></i>' : '<i class="ri-thumb-down-fill" style="    transition: all .4s;font-size: 24px; color: red"></i>';
            obj.attendants_only_see_your_own_chats_formatted = data[i].attendants_only_see_your_own_chats ? '<i class="ri-thumb-up-fill" style="    transition: all .4s;font-size: 24px; color: green"></i>' : '<i class="ri-thumb-down-fill" style="    transition: all .4s;font-size: 24px; color: red"></i>';
            obj.must_have_product_to_give_gain_formatted = data[i].must_have_product_to_give_gain ? '<i class="ri-thumb-up-fill" style="    transition: all .4s;font-size: 24px; color: green"></i>' : '<i class="ri-thumb-down-fill" style="    transition: all .4s;font-size: 24px; color: red"></i>';
            obj.updated = DateTime.fromISO(data[i].updated_at).setZone(getTimezone()).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
            this.pipelinesData.push(obj);
          }
          return true;
        } catch (error) {
          console.log(error.message);
          return false;
        }
      },
      reset () {
        this.query = ''
      },
      deletePipeline(pipeline) {
        Swal.fire({
          title: "Você tem certeza?",
          html: "Será removido o pipeline <b>" + pipeline.name + "</b><br>Essa é uma operação irreversível!!!",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: "SIM, pode remover",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              deleteEntity(pipeline.id, 'pipeline')
                .then(async () => {
                  this.changeShowOverlay({show: false});
                  Swal.fire("Sucesso!", "Removido com sucesso", "success");
                  await this.getAllPipelines()
                }).catch((er) => {
                  console.log(er)
                });
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message)
              console.log(error.message)
              await this.getAllPipelines()
            }
          }
        });
      },
      viewPipeline(id) {
        this.$router.push('/apps/crm-pipeline/' + id);
      },
      createPipeline() {
        this.$router.push('/apps/crm-pipeline');
      },
    },
    async mounted() {
      this.changeShowOverlay({show: true});
      await this.getAllPipelines()
      this.changeShowOverlay({show: false});
    },
    watch: {
      async query(value) {
        if (value === '' || value.length === 0) {
          await this.getAllPipelines()
        }
      }
    }
  }
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- include settings-side.vue -->
      <div class="col-sm-12 col-lg-2 col-md-3">
        <SettingsSide :actualRoute="'pipeline'"></SettingsSide>
      </div>
      <div class="col-lg-10 col-sm-12 col-md-9">
        <div class="card" id="pipelineList" style="min-height: 75vh">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Campos de dados</h5>
            </div>
            <div class="flex-shrink-0">
              <button type="button" class="btn btn-success add-btn" id="create-btn" @click.prevent="createPipeline">
                <i class="ri-add-line align-bottom me-1"></i> Pipeline
              </button>
            </div>
          </div>
          <div class="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
            <div class="row g-3">
                <div class="col-sm-4">
                  <div class="search-box">
                    <input type="text" class="form-control search" placeholder="Buscar..." v-model="query" @keydown.enter.prevent="getAllPipelines(true)" />
                    <i class="ri-search-line search-icon"></i>
                    <i class=" ri-close-line search-icon" style="right: 7px !important; left: unset !important; cursor: pointer !important;" v-show="query.length > 0" @click="reset()"></i>
                  </div>
                  <div class="form-text" v-show="query.length > 0">Aperte ENTER para buscar.</div>
                </div>
            </div>
            </div>
            <div class="card-body">
            <div class="table-responsive table-card" style="min-height: inherit">
              <table class="table table-striped align-middle" id="pipelineTable">
                <thead class="table-primary">
                  <tr>
                    <th>Nome</th>
                    <th>Fases</th>
                    <th>Padrão</th>
                    <th>Distribuição Automática</th>
                    <th>Atendimento</th>
                    <th>Chat</th>
                    <th>Produto</th>
                    <th>Ativo</th>
                    <th>Atualizado Em</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody class="list form-check-all">
                  <tr v-for="(data, index) of pipelinesData" :key="index" :class="data.selected ? 'table-active' : ''">
                    <td>
                      <a href="#" @click.prevent="viewPipeline(data.id)">{{ data.name }}</a>
                    </td>
                    <td>{{ data.phases_formatted }}</td>
                    <td v-html="data.is_default_formatted"></td>
                    <td v-html="data.automatic_distribution_formatted"></td>
                    <td v-html="data.attendants_only_see_your_own_businesses_formatted"></td>
                    <td v-html="data.attendants_only_see_your_own_chats_formatted"></td>
                    <td v-html="data.must_have_product_to_give_gain_formatted"></td>
                    <td v-html="data.status_formatted"></td>
                    <td>{{ data.updated }}</td>
                    <td>
                      <div class="dropdown">
                        <button class="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <i class="ri-more-fill align-middle"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <a class="dropdown-item" @click.prevent="viewPipeline(data.id)" style="cursor:pointer">
                              <i class="ri-pencil-fill align-bottom me-2 text-muted"></i>Editar
                            </a>
                          </li>
                          <li class="dropdown-divider"></li>
                          <li>
                            <a class="dropdown-item remove-item-btn" @click.prevent="deletePipeline(data)" style="cursor:pointer">
                              <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remover
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="noresult" style="display: none">
                <div class="text-center">
                  <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                    :height="75" :width="75" />
                  <h5 class="mt-2">Nenhum Resultado</h5>
                  <p class="text-muted mb-0">
                    Não encontramos nenhum Pipeline para exibir...
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-end p-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="page !== 1" @click="page--">
                    Previous
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li :class="{
                        active: pageNumber === page,
                        disabled: pageNumber === '...', }"
                      v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                      @click="page = pageNumber">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                    Next
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
<style scoped>
.table-striped > tbody > tr.table-active:nth-of-type(odd) > * {
  --bs-table-accent-bg: unset;
}
.option__title {
  margin-left: 10px !important;
}
.character-option-icon {
  margin: 0 6px 0 0;
  height: 22px;
}

.character-label-icon {
  margin: 0 6px 0 0;
  height: 26px;
}
img.avatar {
  border-radius: 50%;
  background-color: #fff;
  height: 40px !important;
  width: 40px !important;
  min-height: 40px !important;
  min-width: 40px !important;
}
</style>
